








































































import BaseComponent from '@/core/base.component';
import { componentRoutes } from '@/router/modules';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'NavBar',
})
export default class NavBar extends BaseComponent {
  private items = componentRoutes;
  private isToggleNav = false;
  private isCollapsed = '';

  onToggleNav() {
    this.isToggleNav = !this.isToggleNav;
  }

  homeToggleNav() {
    if (this.isToggleNav) this.isCollapsed = 'collapse';
    else this.isCollapsed = '';
    this.isToggleNav = false;
  }
}
