




declare global {
  interface Window {
    kakao: any;
  }
}
declare global {
  interface Window {
    Kakao: any;
  }
}
import { Component } from 'vue-property-decorator';
import BaseComponent from './core/base.component';

@Component({
  name: 'App',
})
export default class App extends BaseComponent {
  get layout() {
    return this.$route.meta.layout;
  }
}
