export enum AGE_GROUP {
  AGE_20 = 'AGE_20',
  AGE_30 = 'AGE_30',
  AGE_40 = 'AGE_40',
  AGE_50 = 'AGE_50',
  AGE_60_OVER = 'AGE_60_OVER',
}
export const CONST_AGE_GROUP = Object.values(AGE_GROUP);

export enum HOW_SKILL {
  DIVERSE_CATEGORY_PROF = 'DIVERSE_CATEGORY_PROF',
  ONE_CATEGORY_PROF = 'ONE_CATEGORY_PROF',
}
export const CONST_HOW_SKILL = Object.values(HOW_SKILL);

export enum EXP_GROUP {
  EXP_BETWEEN_FIVE_OVER = 'EXP_BETWEEN_FIVE_OVER',
  EXP_BETWEEN_ONE_TO_THREE = 'EXP_BETWEEN_ONE_TO_THREE',
  EXP_BETWEEN_THREE_TO_FIVE = 'EXP_BETWEEN_THREE_TO_FIVE',
  EXP_UNDER_ONE = 'EXP_UNDER_ONE',
}
export const CONST_EXP_GROUP = Object.values(EXP_GROUP);

export enum HOW_OPERATE {
  NOT_SELF_OPERATING = 'NOT_SELF_OPERATING',
  SELF_OPERATING = 'SELF_OPERATING',
}
export const CONST_HOW_OPERATE = Object.values(HOW_OPERATE);

export enum SKILL_GROUP {
  SKILL_ASSIST = 'SKILL_ASSIST',
  SKILL_CHEF = 'SKILL_CHEF',
  SKILL_INTRO = 'SKILL_INTRO',
  SKILL_MAIN = 'SKILL_MAIN',
}
export const CONST_SKILL_GROUP = Object.values(SKILL_GROUP);
